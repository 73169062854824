import { SignData, ImgInfo } from "@/components/we-upload/type"
import request from "@/server"

// 图片上传 获取签名
export function uploadSign() {
  return request<SignData>({
    url: "/api/backend/system/get_file",
    method: "post",
  })
}
// 图片上传
export function uploadImg<T = any>(data: ImgInfo) {
  return request<T>({
    url: "/api/backend/system/set_file",
    method: "post",
    data,
  })
}
// 获取google 地址信息
export function mapGeocode<T = any>(data: { address: string }) {
  return request<T>({
    url: "/api/map/geocode",
    method: "get",
    data,
  })
}

export function getApi<T = any>(url: string, data: any) {
  return request<T>({
    url: url,
    method: "get",
    data,
  })
}

export function postApi<T = any>(url: string, data: any) {
  return request<T>({
    url: url,
    method: "post",
    data,
  })
}

export function putApi<T = any>(url: string, data: any) {
  return request<T>({
    url: url,
    method: "put",
    data,
  })
}

export function deleteApi<T = any>(url: string, data: any) {
  return request<T>({
    url: url,
    method: "delete",
    data,
  })
}

export function requestApi<T = any>(url: string, method: string = "post", data: any) {
  return request<T>({
    url: url,
    method: method,
    data,
  })
}

// 进度条list 查询
export function planLog<T = any>() {
  return request<T>({
    url: "api/backend/system/plan_log",
    method: "get",
  })
}
// 单条删除
export function deleteLog<T = any>(id: string) {
  return request<T>({
    url: "api/backend/system/delete_log/" + id,
    method: "post",
  })
}
