import { defineStore } from "pinia"
import { login, logout, getConfigData } from "@/api/sys/user"
import { menuQuery, storeQuery } from "@/api/sys/menu"
import Layout from "@/layout/index.vue"
import { getStorage, handleTree } from "@/utils"
import { StorageType } from "@/utils/enum"

interface loginForm {
  username: string
  password: string
}
interface GoogleMap {
  map_id: string
  api_key: string
  server_api_key: string
}
interface UserState {
  account: string
  name: string
  avatarUrl: string
  token: string
  storeId: number | null
  storeList: ElOptions[]
  menuLoad: boolean
  menuList: any[]
  email: string
  googleMap: GoogleMap
}

const User = defineStore({
  id: `${import.meta.env.VITE_TITLE}-user`,
  state: (): UserState => ({
    account: "",
    name: "",
    avatarUrl: "",
    token: "",
    storeId: null,
    storeList: [],
    menuLoad: false, // 菜单已加载
    menuList: [],
    email: "",
    googleMap: {
      map_id: "",
      api_key: "",
      server_api_key: "",
    },
  }),
  getters: {
    getToken(): string {
      return "Bearer " + this.token
    },
  },
  actions: {
    reset() {
      this.$reset()
    },
    setAccount(account: string) {
      this.account = account
    },
    setName(name: string) {
      this.name = name
    },
    setavatarUrl(avatarUrl: string) {
      this.avatarUrl = avatarUrl
    },
    setToken(token: string) {
      this.token = token
    },
    setStoreId(storeId: number) {
      this.storeId = storeId
    },
    setStoreList(storeList: ElOptions[]) {
      this.storeList = storeList
    },
    setMenuLoad(menuLoad: boolean) {
      this.menuLoad = menuLoad
    },
    setMenu(menuList: any[]) {
      this.menuList = menuList
    },
    setEmail(email: string) {
      this.email = email
    },
    setGoogleMap(googleMap: GoogleMap) {
      this.googleMap = googleMap
    },

    /**
     * @description: 登录
     * @param {loginForm} loginForm username password
     *
     */
    async login(loginForm: loginForm): Promise<any> {
      return new Promise((resolve, reject) => {
        login(loginForm)
          .then(async (res: UserState) => {
            this.setToken(res.token)
            await this.configData() // googleMap配置
            resolve(true)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    },
    /**
     * @description: 登出
     */
    async logout(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        logout()
          .then(res => {
            this.setToken("")
            this.$reset()
            // 路由表重置
            location.href = "/"
            resolve(true)
          })
          .catch((err: any) => {
            this.$reset()
            location.href = "#/login"
            reject(err)
          })
      })
    },
    /**
     * @param {boolean} flag 设置菜单
     * @description: 获取菜单信息
     */
    async menuInfo(flag: boolean): Promise<any[]> {
      return new Promise((resolve, reject) => {
        menuQuery()
          .then(res => {
            if (res.length === 0) {
              this.setMenuLoad(true)
              reject("data length is zero")
            }
            const modules = import.meta.glob("@/views/**/*.vue") //匹配views文件
            const setComponent = (view: string) => {
              // 路由懒加载
              for (const path in modules) {
                const dir = path.split("views")[1].split(".vue")[0]
                if (dir === view) {
                  return () => modules[path]()
                }
              }
            }

            const routeList = res.map((item: any) => {
              if (item.component) {
                if (item.component === "Layout") {
                  item.component = Layout
                } else {
                  item.component = setComponent(item.component) // 导入组件
                }
              }
              item.meta = {
                title: item.title,
                icon: item.icon,
              }

              if (item.api_url) item.meta.apiUrl = item.api_url

              return item
            })
            const newRoute = handleTree(routeList, { id: "menuId" })

            this.setMenu(flag ? newRoute : [])
            resolve(newRoute)
          })
          .catch(err => {
            this.setMenuLoad(true)
            reject(err)
          })
      })
    },
    /**
     *
     * @returns
     */
    async storeInfo(): Promise<any[]> {
      return new Promise((resolve, reject) => {
        storeQuery()
          .then(res => {
            this.setStoreList(
              res.map((item: { name: any; id: any }) => {
                return {
                  label: item.name,
                  value: item.id,
                }
              }),
            )
            this.storeId ?? this.setStoreId(res[0]?.id || 0)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async configData(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        getConfigData()
          .then(res => {
            this.setGoogleMap(res.map.google)
            resolve(true)
          })
          .catch(err => {
            console.log("google map is error:" + err)
            resolve(true)
          })
      })
    },
  },
  persist: {
    // 开启持久化
    enabled: true,
    // 选择存储方式和内容
    strategies: [
      {
        storage: sessionStorage,
        paths: ["token", "storeId", "googleMap"],
      },
    ],
  },
})
export default User
