import { createApp } from "vue"
import * as Sentry from "@sentry/vue"

import App from "@/App.vue"
import router from "@/router"
import pinia from "@/stores"
import { i18n } from "@/locales"

// import { setupGlobDirectives } from "@/directives"

import * as ElementPlusIconsVue from "@element-plus/icons-vue"

import "@/styles/index.scss"
import "element-plus/theme-chalk/el-message.css"
import "element-plus/theme-chalk/el-message-box.css"
import "virtual:svg-icons-register" //svg

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// app.config.globalProperties.$t = i18n.global.t
if (import.meta.env.MODE === "production") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_VERSION,
    environment: "production",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/dev.wivoce.cn\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    attachProps: true,
    beforeSend: (event, hint: Sentry.EventHint) => {
      // 403 501 不上报
      return [501, 403].includes((hint.originalException as any)?.code ?? null) ? null : event
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
console.log("12")
// 注册pinia
// setupStore(app)
app.use(pinia).use(router).use(i18n).mount("#app")

// 自定义指令
// setupGlobDirectives(app)
// 打印错误
// app.config.errorHandler = (err) => {
//   console.log("err", err);
// };
