import { createPinia } from "pinia"
import piniaPersist from "pinia-plugin-persist"
const pinia = createPinia()
pinia.use(piniaPersist)
export default pinia

// modules 统一管理
import UserStore from "./modules/user"
import LayoutStore from "./modules/layout"
import CacheStore from "./modules/cache"
export { UserStore, LayoutStore, CacheStore }
