<template>
  <div class="nav-container">
    <!-- aside控制 -->
    <div @click="changeSider" class="nav-container__sign">
      <svg-icon icon="layout-indent" :style="{ transform: `rotate(${LayoutStore().silderStatus ? 0 : 180}deg)` }"
        size="20px" class="nav-container__sign__icon"></svg-icon>
    </div>
    <!-- 面包屑 -->
    <div class="nav-container__breadcrumb">
      <el-breadcrumb separator="/" size="large">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item v-for="item in matched" :key="item.path">
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div>
    <!-- 语言 -->
    <div class="nav-container__menu">
      <div class="nav-container__menu__item">
        <we-dropdown icon="layout-language" size="24px" padding="8px" :options="languageOptions"
          v-model:value="LayoutStore().language"
          @change="(val: any) => LayoutStore().setLanguage(val.value)"></we-dropdown>
      </div>
      <!-- 门店 -->
      <div class="nav-container__menu__item">
        <we-dropdown icon="layout-store" size="24px" padding="8px" :options="UserStore().storeList"
          v-model:value="UserStore().storeId" @change="changeStore"></we-dropdown>
      </div>
      <!-- soket down -->
      <div class="nav-container__menu__item">
        <DownInfoItem>
          <template #icon>
            <svg-icon icon="layout-per" size="24px" padding="8px"></svg-icon>
          </template>
        </DownInfoItem>
      </div>
      <!-- 个人功能菜单 -->
      <div class="nav-container__menu__item">
        <el-dropdown trigger="click">
          <!-- 头像 -->
          <!-- <ElAvatar class="nav-container__menu--img" :src="UserStore().avatarUrl" v-if="UserStore().avatarUrl"></ElAvatar> -->
          <svg-icon icon="layout-setting" size="24px" padding="8px"></svg-icon>
          <!-- <span class="nav-container__menu--text omit" :title="UserStore().name">{{ UserStore().name }}</span> -->
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changeInfo">{{ $t("user.changeEmail") }}</el-dropdown-item>
              <el-dropdown-item @click="changePwd">{{ $t("user.changePwd") }}</el-dropdown-item>
              <el-dropdown-item @click="loginOut">{{ $t("user.logout") }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <!-- 修改邮箱-->
    <el-dialog v-model="infoShow" :title="$t('user.changeEmail')" width="500px" destroy-on-close align-center>
      <info-item @cancel="drwCancel"></info-item>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog v-model="pwdShow" :title="$t('user.changePwd')" width="500px" destroy-on-close align-center>
      <pwd-item @cancel="drwCancel"></pwd-item>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { UserStore, LayoutStore, CacheStore } from "@/stores"
import InfoItem from "./info.vue"
import PwdItem from "./pwd.vue"
import DownInfoItem from "./down-info.vue"
import { messages } from "@/locales"

// import NoteTip from './note-tip.vue';
const route = useRoute()
// 控制aside
const changeSider = () => {
  LayoutStore().changeAsideStatus()
}
const languageOptions = Object.entries(messages).map((item, index) => {
  return {
    value: item[1].value,
    label: item[1].label,
  }
})
// 更改默认尺寸
const setSize = (e: "" | "large" | "default" | "small") => {
  LayoutStore().setSize(e)
}
// 登出
const loginOut = () => {
  UserStore()
    .logout()
    .then(() => {
      // 重置pinia
      LayoutStore().$reset()
      location.href = "#/login"
    })
    .catch(() => { })
}

const matched = computed(() => {
  return route.matched.filter((item: any) => {
    return item.path !== "/"
  })
})

function findPath (list: any[], path: string, oldPath: string) {
  let num = 0
  for (const item of list) {
    num++
    if (item.redirect === oldPath) {
      return oldPath.replace("/", "#")
    } else if (path.indexOf(item.path) > -1) {
      // 寻到地址
      path = path.replace(item.path, "")
      if (path !== "/") {
        // 还有未替换的
        if (item.children) {
          return findPath(item.children, path, oldPath)
        } else {
          // 地址不符合
          return "#login"
        }
      } else {
        // 地址完全符合
        return oldPath.replace("/", "#")
      }
    } else if (list.length === num) {
      // 未寻到地址
      return "#login"
    }
  }
}

const changeStore = async (val: ElOptions) => {
  UserStore().setStoreId(val.value as number)
  const menuData = await UserStore().menuInfo(false)
  location.href = findPath(menuData, route.fullPath, route.fullPath) as string
  location.reload()
}

// 修改个人信息
const infoShow = ref<boolean>(false)
const changeInfo = () => {
  infoShow.value = true
}
// 修改密码
const pwdShow = ref<boolean>(false)
const changePwd = () => {
  pwdShow.value = true
}
const drwCancel = () => {
  infoShow.value = false
  pwdShow.value = false
}
</script>

<style scoped lang="scss">
.nav-container {
  &__sign {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
    cursor: pointer;

    &__icon {
      padding: 8px;
      background: #f89898;
      border-radius: 12px;
    }
  }

  &__breadcrumb {
    line-height: $logoHeight;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }

  &__menu {
    margin-left: auto;
    margin-right: 10px;
    height: $logoHeight;
    display: flex;
    align-items: center;
    position: relative;

    &__item {
      margin: 5px;
      cursor: pointer;

      &:hover {
        background: #ececed;
        border-radius: 8px;
        transition:
          color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }

    &--text {
      margin-left: 10px;
      width: 80px;
    }
  }
}
</style>
