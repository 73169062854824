export default {
  value: "en",
  label: "english",
  user: {
    login: "Login",
    logout: "Exit",
    changeEmail: "Modify email",
    changePwd: "Change password",
    verifyEmail: "Verify email",
    bindEmail: "Bind email",
    sendCode: "SVC",
    next: "Next step",
    pwd: "Current password",
    newPwd: "New password",
    checkPwd: "Confirm password",
    sendEmail: "Sending emails",
    fotgetEmail: "Forgot password",
    plzAccount: "Please enter your account number",
    plzPwd: "Please enter password",
    sysName: "Management system",
  },
  buttons: {
    login: "Login",
    search: "Search",
    reset: "Reset",
    add: "Add",
    delBranch: "Batch Delete",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    save: "Save",
    export: "Export",
    import: "Import",
    action: "Action",
    show: "Show",
    hide: "Hide",
    refresh: "Refresh",
    switchView: "Switch Views",
  },
  tips: {
    loading: "loading",
    success: "success",
    error: "error",
    title: "title",
    confirm: "confirm",
    cancel: "cancel",
    deleteText: "Are you sure to delete？",
    debounce: "Please do not operate too frequently",
    isNull: "Option is empty",
    isRequired: "Inserisci informazioni",
    copy: "replicating success",
    pwd: "Please enter a 6-20 digit login password",
    rePwd: "Please enter your password again",
    checkPwd: "Password inconsistency!",
    verifyEmail: "Please enter the correct emails",
    verifyCode: "Please enter the correct verification code",
    sendCode: "Verification code has been sent",
    sendEmail: "Email sent",
    isBottom: "All loaded",
  },
  upload: {
    success: "Upload successful",
    error: "Upload failed",
    prompt1: "Only",
    prompt2: "format files can be uploaded, and it should not exceed",
    limit: "The limit is #limit, you selected #length files this time, add up to #count totally",
    type: "The current format is #suffix, and uploaded files can only be in #accept format",
    limitMin: "The uploaded file size cannot be equal to 0k",
    limitMax: "The upload file size cannot exceed #size",
  },
  tem: {
    name: "name",
    templete: "template",
    formOption: "form option",
    pageSize: "page size",
    text: "text",
    line: "line",
    table: "table",
    img: "image",
    code128: "bar code",
    qrcode: "QR code",
    conf: "confing",
    titleContent: "content",
    titleTable: "table",
    thead: "thead",
    twid: "width",
    tableErr: "The sum of table widths cannot exceed 100%",
    imgUpload: "image upload",
    left: "left",
    center: "center",
    right: "right",
    bold: "bold",
    size: "size",
    up: "up",
    down: "down",
    del: "delete",
    save: "save",
    type: "type",
    dict: "dict",
  },
}
