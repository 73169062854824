export default {
  value: "zh-cn",
  label: "中文",
  user: {
    login: "登录",
    logout: "退出登录",
    changeEmail: "修改邮箱",
    changePwd: "修改密码",
    verifyEmail: "验证邮箱",
    bindEmail: "绑定邮箱",
    sendCode: "发送验证码",
    next: "下一步",
    pwd: "当前密码",
    newPwd: "新密码",
    checkPwd: "确认密码",
    sendEmail: "发送邮件",
    fotgetEmail: "忘记密码",
    plzAccount: "请输入账号",
    plzPwd: "请输入密码",
    sysName: "后台管理系统",
  },
  buttons: {
    login: "登录",
    search: "查询",
    reset: "重置",
    add: "新增",
    delBranch: "批量删除",
    edit: "编辑",
    delete: "删除",
    cancel: "取消",
    confirm: "确定",
    save: "保存",
    export: "导出",
    import: "导入",
    action: "操作",
    show: "显示",
    hide: "隐藏",
    refresh: "刷新",
    switchView: "切换视图",
  },
  tips: {
    loading: "加载中",
    success: "操作成功",
    error: "操作失败",
    title: "提示",
    confirm: "确认",
    cancel: "取消",
    deleteText: "是否确认删除？",
    isNull: "选项为空",
    isRequired: "请输入信息",
    debounce: "请勿频繁操作",
    copy: "复制成功",
    pwd: "请输入6-20位登录密码",
    rePwd: "请再次输入密码",
    checkPwd: "密码不一致!",
    verifyEmail: "请输入正确的邮箱",
    verifyCode: "请输入正确的验证码",
    sendCode: "验证码已发送",
    sendEmail: "邮件已发送",
    isBottom: "已加载全部",
  },
  upload: {
    success: "上传成功",
    error: "上传失败",
    prompt1: "只能上传",
    prompt2: "格式文件，且不超过",
    limit: "限制为#limit，您这次选择了#length个文件，总共#count个",
    type: "当前格式为 #suffix,上传文件只能是 #accept 格式",
    limitMin: "上传文件大小不能等于0k",
    limitMax: "上传文件大小不能超过 #size",
  },
  tem: {
    templete: "模板",
    formOption: "表单元素",
    pageSize: "尺寸",
    name: "名称",
    text: "文本内容",
    line: "分割线",
    table: "表格",
    img: "图片",
    code128: "条形码",
    qrcode: "二维码",
    conf: "配置项",
    titleContent: "内容",
    titleTable: "表格",
    thead: "表头",
    twid: "宽度",
    tableErr: "表格宽度相加不能超过100%",
    imgUpload: "图片上传",
    left: "居左",
    center: "居中",
    right: "居右",
    bold: "加粗",
    size: "尺寸",
    up: "上移",
    down: "下移",
    del: "删除",
    save: "保存",
    type: "类型",
    dict: "字典",
  },
}
