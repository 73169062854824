import { StorageType } from "./enum"

interface OptionTree {
  id?: string
  pid?: string
  children?: string
}
/**
 * @desc 递归父子关系数数组
 * @param { any[] } list 数据源
 * @param { OptionTree } optionTree 配置项 id pid children
 * @returns { Array } any[] 处理后的数组
 */
const handleTree = (list: any[], optionTree: OptionTree = {}): any[] => {
  const { id = "id", pid = "pid", children = "children" } = optionTree

  const endList: any[] = []
  const obj: any = {}
  const startList: any[] = []

  // 除去无用的顶级父类，放入obj
  list.map(item => {
    if (item[id] !== -1) {
      obj[item[id]] = item
      startList.push(item)
    }
  })

  startList.forEach(item => {
    const fObj = obj[item[pid]]
    if (fObj) {
      ;(fObj[children] || (fObj[children] = [])).push(item)
    } else {
      endList.push(item)
    }
  })

  return endList
}

/**
 * @param key VITE_TITLE-key
 * @param val string
 * @param type
 */
const setStorage = (key: string, val: any, type?: StorageType) => {
  type === StorageType.locale
    ? localStorage.setItem(`${import.meta.env.VITE_TITLE}-${key}`, JSON.stringify(val))
    : sessionStorage.setItem(`${import.meta.env.VITE_TITLE}-${key}`, JSON.stringify(val))
}

/**
 * @param key VITE_TITLE-key
 * @param type
 * @returns
 */
const getStorage = (key: string, type?: StorageType) => {
  const val =
    type === StorageType.locale
      ? localStorage.getItem(`${import.meta.env.VITE_TITLE}-${key}`)
      : sessionStorage.getItem(`${import.meta.env.VITE_TITLE}-${key}`)
  return val ? JSON.parse(val) : ""
}

const removeStorage = (key: string, type?: StorageType) => {
  type === StorageType.locale
    ? localStorage.removeItem(`${import.meta.env.VITE_TITLE}-${key}`)
    : sessionStorage.removeItem(`${import.meta.env.VITE_TITLE}-${key}`)
}

export { handleTree, setStorage, getStorage, removeStorage }
