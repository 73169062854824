<template>
  <div>
    <el-dropdown trigger="click" :hide-on-click="false">
      <slot name="icon"></slot>
      <template #dropdown>
        <el-dropdown-menu class="per-box" v-loading="tableInfo.loading">
          <el-empty v-if="tableInfo.list.length < 1" style="position: relative; height: 100%" />
          <el-dropdown-item v-else v-for="(item, index) in tableInfo.list" :key="item.id">
            <div class="mian">
              <div class="text">
                <div class="omit text-content">
                  {{ `${index + 1}. ${item.name}` }}
                </div>
                <div class="icon-down">
                  <svg-icon
                    icon="layout-download"
                    padding="5px"
                    size="20px"
                    v-if="item.url?.id"
                    @click="download(item.url, item.name)"
                  ></svg-icon>
                  <svg-icon icon="layout-del" padding="5px" size="20px" @click="onDelete(item)"></svg-icon>
                </div>
              </div>
              <el-progress
                :percentage="item.per"
                :status="statusObj[item.status]"
                striped-flow
                :duration="10"
                :striped="item.status === 0"
                class="per"
                :show-text="false"
              />
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup lang="ts">
import { UserStore } from "@/stores"
import { i18n } from "@/locales"
import { planLog, deleteLog } from "@/api/common"
import { toast } from "@/utils/tip"

const { t } = i18n.global
const userStore = UserStore()

type PerStatus = 0 | 1 | 3
type UrlInfo = {
  id: number
  name: string
  type: string
  url: string
}
type PerInfo = {
  id: string
  name: string
  status: PerStatus
  per: number
  date?: number
  url?: UrlInfo
}

const planChannel = window.echo.join(`plan.${userStore.storeId}`)
planChannel.listen(".plan_create", (data: any) => {
  tableInfo.list.unshift({
    id: data.log.id,
    name: data.log.name,
    status: 3,
    per: 0,
    url: data.log.url,
    date: new Date().getTime(),
  })
})
planChannel.listen(".plan_success", (data: any) => {
  const index = tableInfo.list.findIndex(item => item.id === data.log.id)

  if (index !== -1) {
    tableInfo.list[index] = {
      id: data.log.id,
      name: data.log.name,
      status: data.log.status,
      per: 100,
      url: data.log.url,
      date: new Date().getTime(),
    }
  }
})
planChannel.listen(".plan_error", (data: any) => {
  const index = tableInfo.list.findIndex(item => item.id === data.log.id)
  if (index !== -1) {
    tableInfo.list[index] = {
      id: data.log.id,
      name: data.log.name,
      status: data.log.status,
      per: 100,
      url: data.log.url,
      date: new Date().getTime(),
    }
  }
})

const statusObj: ("" | "success" | "warning" | "exception")[] = ["", "success", "", "exception"]
// status 0开始  1结束  3异常
const tableInfo = reactive({
  loading: false,
  list: <PerInfo[]>[],
})

// 下载
const download = (url: UrlInfo, name: string) => {
  if (url.type.indexOf("image") > 0) {
    // 处理oss图片
    let image = new Image()
    image.setAttribute("crossOrigin", "anonymous")
    image.src = url.url
    image.onload = () => {
      let canvas = document.createElement("canvas")
      canvas.width = image.width
      canvas.height = image.height
      let ctx = canvas.getContext("2d")
      ctx!.drawImage(image, 0, 0, image.width, image.height)
      canvas.toBlob(blob => {
        let url = URL.createObjectURL(blob!)
        let a = document.createElement("a")
        a.download = name
        a.href = url
        a.click()
        a.remove()
        // 用完释放URL对象
        URL.revokeObjectURL(url)
      })
    }
  } else {
    window.open(url.url)
  }
}

const onDelete = (perInfo: PerInfo) => {
  deleteLog(perInfo.id).then(res => {
    tableInfo.list = tableInfo.list.filter(item => item.id !== perInfo.id)
    toast.success(t("tips.success"))
  })
}

let timer: NodeJS.Timeout | undefined
const autoPer = async () => {
  clearInterval(timer)
  const res = await planLog()
  tableInfo.list = res.data.map((item: { url: any; id: any; name: any; status: any }) => {
    return {
      id: item.id,
      name: item.name,
      status: item.status,
      per: item.status === 0 ? 0 : 100,
      url: item.url,
      date: new Date().getTime(),
    }
  })
  // tableInfo.list = [
  //   {
  //     id: '1',
  //     name: 'name',
  //     status: 0,
  //     per: 100,
  //     // url: 'https://test-static.wivoce.cn/wivoce/20240927/wivoce-boss-1.0.1.apk',
  //     url: 'https://test-static.wivoce.cn/wivoce/20241226/1735187733899联想.png',
  //     date: new Date().getTime(),
  //   }
  // ]

  if (!tableInfo.list.length) return
  timer = setInterval(() => {
    tableInfo.list.forEach(item => {
      if (item.status === 0) {
        const round = Math.random() * Math.random() * 10 + 0.1
        // const num = (1 - Math.exp((-1 * (new Date().getTime() - item.date!)) / (60 * 1000))) * 100
        // const roundNum = num * round

        // if (roundNum > item.per) {
        //   item.per = num >= 95 ? 95 : parseFloat(roundNum.toFixed(2))
        // }

        item.per = item.per + round > 95 ? 95 : parseFloat((item.per + round).toFixed(2))
      } else {
        item.per = 100
      }
    })
  }, 1 * 1000)
}
autoPer()

// 切换门店重新调用
watch(
  () => UserStore().storeId,
  () => {
    autoPer()
  },
)
</script>
<style scoped lang="scss">
.per-table {
  height: 500px;
  overflow-y: auto;
}

.per-box {
  width: 350px;
  height: 500px;

  .mian {
    position: relative;
    width: 330px;
    padding: 10px;

    .text {
      display: flex;

      .text-content {
        width: 230px;
      }

      .icon-down {
        width: 70px;
        position: relative;
        margin-left: auto;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
      }
    }

    :deep(.el-progress__text) {
      min-width: 20px !important;
    }
  }

  .span-text {
    width: 80px;
  }

  .btnBox {
    margin-left: auto;
    float: right;
    width: 150px;
    text-align: right;
  }
}

:deep(.el-dropdown-menu__item:hover) {
  cursor: default;
}
</style>
